import { padNumber } from './StringFunctions';

const AllNaughts = Object.freeze({
  days: 0,
  hours: 0,
  minutes: 0,
  seconds: 0
});

export const formatDurationToDisplayTime = (
  duration: Duration,
  options?: { withSuffix?: boolean; withExtraSpace?: boolean }
) => {
  const { withSuffix = false, withExtraSpace = false } = options || {};
  const parts = [];
  if (duration.days > 0) {
    parts.push(
      `${duration.days < 10 ? '0' : ''}${duration.days}${withSuffix ? 'd' : ''}`
    );
  }
  if (duration.hours > 0) {
    parts.push(
      `${duration.hours < 10 ? '0' : ''}${duration.hours}${
        withSuffix ? 'h' : ''
      }`
    );
  }
  parts.push(
    `${duration.minutes < 10 ? '0' : ''}${duration.minutes}${
      withSuffix ? 'm' : ''
    }`
  );
  parts.push(
    `${duration.seconds < 10 ? '0' : ''}${duration.seconds}${
      withSuffix ? 's' : ''
    }`
  );
  const separator = withExtraSpace ? ': ' : ':';
  return parts.join(separator);
};

export const formatSecondsToDisplayTime = (
  value: number,
  options?: { withSuffix?: boolean; withExtraSpace?: boolean }
) => {
  const duration = getDurationParts(value);
  return formatDurationToDisplayTime(duration, options);
};

/**
 * Formats the total duration in the format "00d : 00h : 00m : 00s"
 *
 * @param value The total duration in seconds.
 */
export const formatDuration = (value: number) => {
  const { days, hours, minutes, seconds } = getDurationParts(value);
  return (
    (days < 10 ? '0' : '') +
    days.toLocaleString() +
    'd : ' +
    (hours < 10 ? '0' : '') +
    hours.toLocaleString() +
    'h : ' +
    (minutes < 10 ? '0' : '') +
    minutes.toLocaleString() +
    'm : ' +
    (seconds < 10 ? '0' : '') +
    seconds.toLocaleString() +
    's'
  );
};

/**
 * Formats the total duration in the format "00 : 00"
 *
 * @param value The total duration in seconds.
 */
export const getMinutesAndSeconds = (value: number): string => {
  const { minutes, seconds } = getDurationParts(value);
  return formatMinuteSeconds(minutes, seconds);
};

/**
 * Formats the total duration in the format "00 : 00"
 *
 */
export const formatMinuteSeconds = (minutes: number, seconds: number) => {
  return (
    (minutes < 10 ? '0' : '') +
    minutes.toString() +
    ':' +
    (seconds < 10 ? '0' : '') +
    seconds.toString()
  );
};

export const formatMillisecondsDuration = (value: number) => {
  const milliseconds = value % 1000;
  value = (value - milliseconds) / 1000;
  const seconds = value % 60;
  value = (value - seconds) / 60;
  const minutes = value % 60;
  return `${padNumber(minutes, 2)}:${padNumber(seconds, 2)}.${padNumber(
    milliseconds,
    2
  )}`;
};

/**
 * Returns the parts that make up the seconds.
 *
 * @param value The total duration in seconds.
 */
export const getDurationParts = (totalSeconds: number) => {
  if (!totalSeconds || totalSeconds <= 0) {
    return AllNaughts;
  }

  const days = Math.floor(totalSeconds / 86400);
  const hours = Math.floor((totalSeconds % 86400) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = Math.floor((totalSeconds % 3600) % 60);

  return {
    days,
    hours,
    minutes,
    seconds
  };
};

/**
 * Returns the duration in the format of either day/days if the total seconds is more than a day.
 * If the total seconds is less than a single day, the duration is formatted in hh:mm:ss
 *
 * @param totalSeconds The total duration to be formatted
 */

export const formatDurationFriendly = (totalSeconds: number) => {
  const { days, hours, minutes, seconds } = getDurationParts(totalSeconds);
  if (days > 1) {
    return `${days.toLocaleString()} days`;
  }
  if (days === 1) {
    return `${days.toLocaleString()} day`;
  }
  if (hours > 0) {
    return `${hours < 10 ? `0${hours}` : hours}:${getMinutesAndSeconds(
      totalSeconds
    )}`;
  }
  if (minutes > 0) {
    return getMinutesAndSeconds(totalSeconds);
  }
  if (seconds > 0) {
    return `${totalSeconds} seconds`;
  }
  return null;
};

const partFormats = {
  Abbreviated: { days: 'd', hours: 'h', minutes: 'm', seconds: 's' },
  Full: {
    days: ' days',
    hours: ' hours',
    minutes: ' minutes',
    seconds: ' seconds'
  },
  None: {
    days: '',
    hours: '',
    minutes: '',
    seconds: ''
  }
};

export type DurationFormatType = 'Abbreviated' | 'Full' | 'None';

/**
 * Format the duration in a condensed manner, down to a specified number of "parts" (i.e. depth).
 * @param seconds The seconds value to format into duration.
 * @param depth The number of duration parts to return.
 * @param separator The separator to combine.
 * @param partFormatType Abbreviated: d/h/m/s. Full: days/hours/minutes/seconds
 * @example
 *
 */
export const formatDurationCondensed = (
  seconds: number,
  depth: number = 2,
  separator: string = ' ',
  partFormatType: DurationFormatType = 'Abbreviated'
) => {
  const parts = getDurationParts(seconds);
  let currentDepth = 0;
  const retParts: string[] = [];
  let partFormat = partFormats.Full;
  switch (partFormatType) {
    case 'Abbreviated':
      partFormat = partFormats.Abbreviated;
      break;
    case 'None':
      partFormat = partFormats.None;
      break;
  }

  if (parts.days > 0) {
    currentDepth++;
    retParts.push(`${parts.days}${partFormat.days}`);
    if (currentDepth == depth) {
      return retParts.join(separator);
    }
  }

  if (parts.hours > 0 || currentDepth > 0) {
    currentDepth++;
    retParts.push(`${parts.hours}${partFormat.hours}`);
    if (currentDepth == depth) {
      return retParts.join(separator);
    }
  }

  if (parts.minutes > 0 || currentDepth > 0) {
    currentDepth++;
    retParts.push(`${parts.minutes}${partFormat.minutes}`);
    if (currentDepth == depth) {
      return retParts.join(separator);
    }
  }

  if (parts.seconds > 0 || currentDepth > 0) {
    currentDepth++;
    retParts.push(`${parts.seconds}${partFormat.seconds}`);
    if (currentDepth == depth) {
      return retParts.join(separator);
    }
  }

  return retParts.join(separator);
};

interface FormatDurationOptions {
  depth: number;
  separator: string;
  partFormatType: DurationFormatType;
  /**
   * The default value to return when seconds are 0.
   */
  emptyDefault?: string;
}

const defaultOptions: FormatDurationOptions = {
  depth: 2,
  separator: ' ',
  partFormatType: 'Abbreviated'
};

/**
 * formatDurationCondensed with a slightly cleaner interface
 * @see formatDurationCondensed
 * @param seconds
 * @param options
 */
export const formatDurationCondensed2 = (
  seconds: number,
  options: Partial<FormatDurationOptions> = {}
) => {
  options = { ...defaultOptions, ...options };

  if (!seconds) return options.emptyDefault;

  return formatDurationCondensed(
    seconds,
    options.depth,
    options.separator,
    options.partFormatType
  );
};
