import { Guid } from '@/common/models/Guid';
import { asBoolean } from '@/common/utils/BooleanFunctions';

import { IntegrationDefinition } from './IntegrationDefinition';
import { SiteIntegrationInstanceProperties } from './SiteIntegrationInstanceProperties';

export class SiteIntegrationInstance {
  id: Guid;
  integration: IntegrationDefinition;
  siteId: Guid;
  properties: SiteIntegrationInstanceProperties;
  isNew?: boolean;

  constructor(props?: Partial<SiteIntegrationInstance>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrEmpty(props.id);
    this.siteId = Guid.valueOrEmpty(props.siteId);
    this.integration = new IntegrationDefinition(props.integration);
    this.properties = new SiteIntegrationInstanceProperties(props.properties);
    this.isNew = asBoolean(props.isNew);
  }

  getDisplayName() {
    if (this.properties?.Name) {
      return `${this.integration.name} - ${this.properties.Name}`;
    }

    return this.integration.name;
  }
}
