import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';
import {
  utcDateOrNow,
  utcDateOrUndefined,
  utcFormatDateTime
} from '@/common/utils/DateFunctions';
import { formatDurationCondensed } from '@/common/utils/DurationFunctions';
import { asAbsolute } from '@/common/utils/NumberFunctions';

import { AllLifeCycleEvents } from '../LifeCycleEvents';
import { DistributionKinds } from './DistributionKinds';
import { DistributionProperties } from './DistributionProperties';
import { DistributionStatuses } from './DistributionStatuses';
import { DistributionTriggers } from './DistributionTriggers';

export class Distribution {
  [immerable] = true;
  id: Guid;
  name: string = '';
  isActive: boolean = true;
  customEmailSenderId?: Guid;
  kind: DistributionKinds = DistributionKinds.Email;
  trigger: DistributionTriggers = DistributionTriggers.Manual;
  status?: DistributionStatuses;
  body: string = '';
  emailStateJson?: string;
  bodyBackgroundColor?: string = '#F4F4F4';
  contentBackgroundColor?: string;
  smsNumber?: string;
  emailSubject: string = '';
  emailPreheader: string = '';
  emailFromName?: string;
  emailReplyTo?: string;
  updatedAt: Date;
  scheduledAt?: Date;
  competitionId?: Guid;
  workspaceId: Guid;
  prizeId?: Guid;

  /** @obsolete use the specific action for getting recipient counts
   */
  recipientsCount: number = 0;

  prizeCouponOfferId?: Guid;
  prizeCouponOfferName?: string;
  competitionCouponOfferId?: Guid;
  competitionCouponOfferName?: string;
  contactListId?: Guid;
  contactListName?: string;
  reminderCouponOfferId?: Guid;
  lastSent?: Date;
  properties: DistributionProperties;
  tags: string[] = [];
  unsubscribeGroupId?: Guid;
  communicationSubscriptionId?: Guid;
  communicationSubscriptionName?: string;
  smsSenderId?: Guid;

  constructor(props?: Partial<Distribution>) {
    props = props || {};
    Object.assign(this, props);
    this.id = Guid.valueOrNew(this.id);
    this.competitionId = Guid.valueOrUndefined(this.competitionId);
    this.workspaceId = Guid.valueOrEmpty(this.workspaceId);
    this.reminderCouponOfferId = Guid.valueOrUndefined(
      props.reminderCouponOfferId
    );
    this.scheduledAt = utcDateOrUndefined(this.scheduledAt);
    this.properties = new DistributionProperties(this.properties);
    this.tags = props.tags || [];
    this.unsubscribeGroupId = Guid.valueOrUndefined(this.unsubscribeGroupId);
    this.communicationSubscriptionId = Guid.valueOrUndefined(
      this.communicationSubscriptionId
    );
    this.customEmailSenderId = Guid.valueOrUndefined(this.customEmailSenderId);
    this.smsSenderId = Guid.valueOrUndefined(this.smsSenderId);
    this.updatedAt = utcDateOrNow(this.updatedAt);
    this.lastSent = utcDateOrUndefined(this.lastSent);

    this.prizeId = Guid.valueOrUndefined(this.prizeId);
    this.prizeCouponOfferId = Guid.valueOrUndefined(this.prizeCouponOfferId);
    this.competitionCouponOfferId = Guid.valueOrUndefined(
      props.competitionCouponOfferId
    );
    this.contactListId = Guid.valueOrUndefined(props.contactListId);
  }

  getKindText() {
    switch (this.kind) {
      case DistributionKinds.Email:
        return 'Transactional email';
      case DistributionKinds.MarketingEmail:
        return 'Marketing email';
      case DistributionKinds.Sms:
        return 'SMS';
      default:
        return 'Unknown';
    }
  }

  getTriggerText() {
    switch (this.trigger) {
      case DistributionTriggers.Manual:
        return 'Manually sent';
      case DistributionTriggers.FormRegister:
        return 'Form complete';
      case DistributionTriggers.Prize:
        return 'Prize awarded';
      case DistributionTriggers.Scheduled:
        return this.scheduledAt
          ? utcFormatDateTime(this.scheduledAt)
          : 'Scheduled';
      case DistributionTriggers.LifeCycle:
        if (
          this.properties.lifeCycleData.offsetSeconds &&
          this.properties.lifeCycleData.offsetSeconds !== 0
        ) {
          return `${formatDurationCondensed(
            asAbsolute(this.properties.lifeCycleData.offsetSeconds)
          )} ${
            this.properties.lifeCycleData.offsetSeconds > 0 ? 'after' : 'before'
          } ${this.getLifeCycleEventLabel(
            this.properties.lifeCycleData.eventName
          )}`;
        }
        return `On ${this.getLifeCycleEventLabel(
          this.properties.lifeCycleData.eventName
        )}`;
      default:
        return 'Unknown';
    }
  }

  getCompetitionCouponText() {
    switch (this.trigger) {
      case DistributionTriggers.Manual:
      case DistributionTriggers.FormRegister:
      case DistributionTriggers.Scheduled:
      case DistributionTriggers.LifeCycle:
        return this.competitionCouponOfferName;
      case DistributionTriggers.Prize:
        return this.prizeCouponOfferName;
      default:
        return '';
    }
  }

  getTriviaCouponText() {
    switch (this.trigger) {
      case DistributionTriggers.Prize:
        return this.prizeCouponOfferName;
      case DistributionTriggers.Manual:
      case DistributionTriggers.FormRegister:
      case DistributionTriggers.Scheduled:
      case DistributionTriggers.LifeCycle:
      default:
        return 'N/A';
    }
  }

  getRelevantCouponOfferId() {
    if (this.trigger === DistributionTriggers.Prize) {
      return this.prizeCouponOfferId;
    }

    return this.competitionCouponOfferId;
  }

  isPrize() {
    return this.trigger === DistributionTriggers.Prize;
  }

  IsSms() {
    return this.kind === DistributionKinds.Sms;
  }

  IsEmail() {
    return (
      this.kind === DistributionKinds.Email ||
      this.kind === DistributionKinds.MarketingEmail
    );
  }

  get hasSubscription() {
    return !!this.communicationSubscriptionId;
  }

  clone() {
    return new Distribution(this);
  }

  get isInvalidMarketing() {
    return (
      this.kind == DistributionKinds.MarketingEmail &&
      !this.unsubscribeGroupId &&
      !this.communicationSubscriptionId
    );
  }

  private getLifeCycleEventLabel(lifeCycleEvent: string | undefined) {
    return (
      AllLifeCycleEvents.find((e) => e.value === lifeCycleEvent)?.label ??
      this.properties.lifeCycleData?.triggerLabel ??
      'Unknown Event'
    );
  }
}
