import { immerable } from 'immer';

import { Guid } from '@/common/models/Guid';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { asNumberOrUndefined } from '@/common/utils/NumberFunctions';

export class DistributionProperties {
  [immerable] = true;
  lifeCycleData?: LifeCycleData;
  useRawDistributionContent?: boolean;

  constructor(props?: Partial<DistributionProperties>) {
    props = props || {};
    Object.assign(this, props);
    this.lifeCycleData = new LifeCycleData(props.lifeCycleData);
    this.useRawDistributionContent = asBoolean(props.useRawDistributionContent);
  }
}

export class LifeCycleData {
  eventName: string;
  entityId?: Guid;
  offsetSeconds?: number;
  triggerLabel: string;

  constructor(props?: Partial<LifeCycleData>) {
    props = props || {};
    Object.assign(this, props);
    this.entityId = Guid.valueOrUndefined(props.entityId);
    this.offsetSeconds = asNumberOrUndefined(props.offsetSeconds);
  }
}
