import { immerable } from 'immer';

import { ColorPalette } from '@/common/models/ColorPalette';
import { asBoolean } from '@/common/utils/BooleanFunctions';
import { colorRgbaOrUndefined } from '@/common/utils/ColorRgbaFunctions';
import { asNumber } from '@/common/utils/NumberFunctions';
import {
  classFromJsonOrFallback,
  classFromJsonOrNew
} from '@/common/utils/TypeFunctions';
import { ISiteThemeProperties } from '@/theme';

import { ButtonDataModel } from '../ButtonDataModel';
import { ColorRGBA } from '../ColorRGBA';
import { ImageDataModel } from '../ImageDataModel';
import { SiteEntryConditionsKinds } from './SiteEntryConditionsKinds';

const black = '#000000';
const white = '#FFFFFF';
const defaults = {
  bgColor: '#EFEFF4',
  headerLogoHeight: 50,

  primaryButton: {
    bgColor: black,
    borderColor: black,
    color: white
  },
  secondaryButton: {
    bgColor: white,
    borderColor: black,
    color: black
  },

  identifiedHeader: 'Welcome back!',
  identifiedSignInText: 'Sign in to continue',
  emailVerifyHeader: 'Almost there!',
  emailVerifyText:
    'Please enter the verification code that we sent to your email.',

  signInHeader: 'Welcome back!',
  signInText: 'Save time entering competitions',
  signInSuccessMessage: 'Welcome back!',

  signUpHeader: 'Sign up',
  signUpText: 'Enter competitions quickly and easily',
  signUpSuccessHeader: "You're in!",
  signUpCheckboxText: 'Want to save time entering competitions?',

  forgotPasswordHeader: 'Forgot your password?',
  forgotPasswordText: "Don't worry, happens to the best of us",
  forgotPasswordConfirmationText:
    "We've sent password reset instructions to your email",

  entryConditionsColor: black,
  entryConditionsBgColor: white,
  entryConditionsType: SiteEntryConditionsKinds.Date,
  entryConditionsSubmitText: 'Submit',
  entryConditionsMinAge: 18,

  cookieConsentHeader: 'Cookies',
  cookieConsentColor: black,
  cookieConsentBgColor: white,
  cookieConsentButtonColor: white,
  cookieConsentButtonBgColor: black,
  cookieConsentTitle: '🍪 Cookie Notice',
  cookieConsentText:
    'We use cookies and similar technologies for site functionality, marketing, and to enhance your online experience. Optional cookies are only set with your consent. For details on our cookies and how we handle your personal information, please refer to our Privacy Policy.'
};

export class SiteProperties implements ISiteThemeProperties {
  [immerable] = true;
  // Brand
  BrandImageUrl?: string;

  get BrandImage() {
    return ImageDataModel.fromJsonOrUrl(this.BrandImageUrl);
  }

  AccentTextColor?: string;
  AccentBackgroundColor?: string;

  SimpleColorsJson?: string;
  get SimpleColors() {
    return classFromJsonOrNew(ColorPalette, this.SimpleColorsJson);
  }

  // Header
  HeaderEnabled: boolean;
  HeaderLogoEnabled: boolean;
  HeaderLogoHeight?: number;
  HeaderBackgroundColor?: string;
  HeaderTextColor?: string;

  // Cover
  BillboardEnabled: boolean;
  BillboardImageUrl?: string;

  get BillboardImage() {
    return ImageDataModel.fromJsonOrUrl(this.BillboardImageUrl);
  }

  // Footer
  FooterEnabled: boolean;
  FooterLogoEnabled: boolean;
  FooterTextColor?: string;
  FooterBrandImageUrl?: string;

  get FooterBrandImage() {
    return ImageDataModel.fromJsonOrUrl(this.FooterBrandImageUrl);
  }

  FooterBackgroundColor?: string;
  FooterContent?: string;
  FooterContentEditorStateJson?: string;

  // SEO
  MetaTitle?: string;
  MetaDescription?: string;
  MetaImage?: string;

  get MetaImageModel() {
    return ImageDataModel.fromJsonOrUrl(this.MetaImage);
  }

  MetaIcon?: string;

  get MetaIconImage() {
    return ImageDataModel.fromJsonOrUrl(this.MetaIcon);
  }

  IsGoogleIndexed: boolean;
  GoogleSiteVerificationCode?: string;

  //White labeling
  DomainWhitelabeled: boolean;
  Whitelabeled: boolean;

  // Social
  SocialNetworkEnabled: boolean;
  FacebookUrl?: string;
  TwitterUrl?: string;
  InstagramUrl?: string;
  LinkedinUrl?: string;
  YoutubeUrl?: string;
  WebSiteUrl?: string;
  TikTokUrl?: string;
  SnapchatUrl?: string;
  RedditUrl?: string;
  PinterestUrl?: string;

  //Background
  BackgroundImageEnabled: boolean;
  BackgroundColor?: string;
  BackgroundImage?: string;

  get BackgroundImageModel() {
    return ImageDataModel.fromJsonOrUrl(this.BackgroundImage);
  }

  BackgroundFilter?: ColorRGBA;
  getBackgroundFilterColor() {
    return this.BackgroundFilter?.hex;
  }

  //Auth
  AuthEnabled?: boolean;
  AuthInitialised?: boolean;
  AuthPrivacyLabel?: string;
  AuthPrivacyLabelEditorStateJson?: string;
  AuthUserAccountsHideHeaderSignInButton?: boolean;
  AuthIconPath?: string;

  get AuthIcon(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.AuthIconPath);
  }

  AuthSsoOAuthEnabled: boolean;
  AuthSubmitButtonColor: string;
  AuthButtonTextColor: string;
  AuthHeaderButtonColor?: string;
  AuthHeaderButtonBackgroundColor?: string;
  AuthOutlineButtonColor: string;
  AuthSignUpHeader: string;
  AuthSignUpSubText: string;
  AuthSignUpFormHeader: string;
  AuthSignUpFormSubText: string;
  AuthSignUpDataCaptureHeader: string;
  AuthSignUpDataCaptureSubText: string;
  AuthSignInHeader: string;
  AuthSignInSubText: string;
  AuthSignUpCheckbox: string;
  AuthRecoveryHeader: string;
  AuthRecoveryFormText: string;
  AuthRecoveryConformationSubText: string;
  AuthSignInFormHeader: string;
  AuthSignInFormSubText: string;
  AuthSignInConfirmation: string;
  AuthVerificationHeader: string;
  AuthVerificationSubText: string;
  AuthSignInVerificationHeader: string;
  AuthSignInVerificationSubText: string;
  AuthMagicLinkEnabled?: boolean;

  //Entry Conditions
  EntryConditionsInitialised?: boolean;
  EntryConditionsEnabled?: boolean;
  EntryConditionsTextColor?: string;
  EntryConditionsBackgroundColor?: string;
  EntryConditionsBackgroundImage?: string;

  get EntryConditionsBackgroundImageModel(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.EntryConditionsBackgroundImage);
  }

  EntryConditionsLogo?: string;

  get EntryConditionsLogoImage(): ImageDataModel {
    return ImageDataModel.fromJsonOrUrl(this.EntryConditionsLogo);
  }

  EntryConditionsFilter?: ColorRGBA;
  EntryConditionsHeader?: string;
  EntryConditionsText?: string;
  EntryConditionsType?: SiteEntryConditionsKinds;
  EntryConditionsFeedbackText?: string;
  EntryConditionsMinimumAge?: number;
  EntryConditionsButtonText?: string;
  EntryConditionsButtonBackgroundColor?: string;
  EntryConditionsButtonColor?: string;

  //Distributions
  SmsSenderName?: string;

  // GDPR/Cookie consent
  CookieConsentEnabled?: boolean;
  CookieConsentTitle?: string;
  CookieConsentContent?: string;
  CookieConsentContentEditorStateJson?: string;
  CookieConsentTextColor?: string;
  CookieConsentBackgroundColor?: string;
  CookieConsentButtonTextColor?: string;
  CookieConsentButtonBackgroundColor?: string;

  // Scripts
  ScriptHead?: string;
  ScriptBody?: string;

  // Realtime content updates (card publishes/unpublishes, card updates)
  RealtimeContentUpdatesEnabled?: boolean;

  BadgesEnabled?: boolean;
  ProfileDrawerEnabled?: boolean;
  ProfileAlbumEnabled?: boolean;
  BadgeBorderColor?: string;
  BadgeTextColor?: string;
  LockedBadgeBorderColor?: string;
  LockedBadgeTextColor?: string;

  //Draft settings
  DraftPreviewPassword?: string;

  //Font settings
  Font?: string;

  // SSR User creation enabled - should default to true for all new hubs
  ServerSideUserCreationEnabled: boolean;

  //RIO settings
  ROIEnabled?: boolean;
  ROIEntryValue?: number;
  ROIViewValue?: number;
  ROIIndustry?: string;

  // Contacts settings

  ContactVerifyShowSignInButton?: boolean;
  ContactVerifyOnFirstAppearance?: boolean;
  ContactVerifyAlwaysShowAllFields?: boolean;
  ContactVerifyFormJson?: string;

  ContactVerifySignInJson?: string;

  get ContactVerifyForm(): ContactVerifyFormModel {
    return ContactVerifyFormModel.fromJsonOrNew(this.ContactVerifyFormJson);
  }

  get ContactVerifySignIn(): ContactVerifySignInModel {
    return ContactVerifySignInModel.fromJsonOrNew(this.ContactVerifySignInJson);
  }

  //Contact Profile settings
  ProfileAlbumJson: string;
  ProfileAlbumShareJson: string;
  getProfileAlbumShareModel() {
    return ButtonDataModel.fromJsonOrNew(this.ProfileAlbumShareJson, {
      backgroundColor: black,
      color: white,
      text: 'Share badge album'
    });
  }
  ProfileAlbumUpdatedAt?: Date;
  ProfileDrawerDataJson?: string;
  getProfileDrawerModel() {
    return ProfileDrawerModel.fromJsonOrNew(this.ProfileDrawerDataJson);
  }
  UseGlobalAvatars?: boolean;
  DisableBadgeGrayscale?: boolean;

  static defaults = defaults;

  constructor(props?: Partial<SiteProperties>) {
    props = props || {};
    Object.assign(this, props);

    this.BackgroundImageEnabled = asBoolean(props.BackgroundImageEnabled);
    this.BackgroundColor ||= defaults.bgColor;
    this.HeaderLogoHeight = asNumber(
      props.HeaderLogoHeight,
      defaults.headerLogoHeight
    );

    this.AuthEnabled = asBoolean(props.AuthEnabled);
    this.AuthUserAccountsHideHeaderSignInButton = asBoolean(
      props.AuthUserAccountsHideHeaderSignInButton
    );
    this.AuthInitialised = asBoolean(props.AuthInitialised);
    this.HeaderEnabled = asBoolean(props.HeaderEnabled);
    this.HeaderLogoEnabled = asBoolean(props.HeaderLogoEnabled);
    this.BillboardEnabled = asBoolean(props.BillboardEnabled);
    this.FooterEnabled = asBoolean(props.FooterEnabled);
    this.FooterLogoEnabled = asBoolean(props.FooterLogoEnabled);
    this.SocialNetworkEnabled = asBoolean(props.SocialNetworkEnabled);
    this.IsGoogleIndexed = asBoolean(props.IsGoogleIndexed);
    this.DomainWhitelabeled = asBoolean(props.DomainWhitelabeled);
    this.Whitelabeled = asBoolean(props.Whitelabeled);
    this.EntryConditionsEnabled = asBoolean(props.EntryConditionsEnabled);
    this.EntryConditionsInitialised = asBoolean(
      props.EntryConditionsInitialised
    );
    this.AuthMagicLinkEnabled = asBoolean(props.AuthMagicLinkEnabled);
    this.CookieConsentEnabled = asBoolean(props.CookieConsentEnabled);

    this.ROIEnabled = asBoolean(props.ROIEnabled);
    this.ROIEntryValue = asNumber(props.ROIEntryValue, undefined);
    this.ROIViewValue = asNumber(props.ROIViewValue, undefined);

    if (props.BackgroundFilter)
      this.BackgroundFilter = colorRgbaOrUndefined(props.BackgroundFilter);

    this.RealtimeContentUpdatesEnabled = asBoolean(
      props.RealtimeContentUpdatesEnabled
    );
    this.BadgesEnabled = asBoolean(props.BadgesEnabled);
    this.ProfileAlbumEnabled = asBoolean(props.ProfileAlbumEnabled);
    this.ProfileDrawerEnabled = asBoolean(props.ProfileDrawerEnabled);

    this.ServerSideUserCreationEnabled = asBoolean(
      props.ServerSideUserCreationEnabled
    );

    this.UseGlobalAvatars = asBoolean(props.UseGlobalAvatars);
    this.DisableBadgeGrayscale = asBoolean(props.DisableBadgeGrayscale);

    if (props.ProfileAlbumUpdatedAt) {
      this.ProfileAlbumUpdatedAt = new Date(props.ProfileAlbumUpdatedAt);
    }

    //Auth Defaults
    this.AuthOutlineButtonColor ||= black;
    this.AuthSubmitButtonColor ||= black;
    this.AuthButtonTextColor ||= white;
    this.AuthSignUpHeader ||= defaults.signUpSuccessHeader;
    this.AuthSignUpSubText ||= defaults.signUpText;
    this.AuthSignUpFormHeader ||= defaults.signUpHeader;
    this.AuthSignUpFormSubText ||= defaults.signUpText;
    this.AuthSignUpDataCaptureHeader ||= defaults.signUpSuccessHeader;
    this.AuthSignUpDataCaptureSubText ||= defaults.signUpText;
    this.AuthSignInHeader ||= defaults.identifiedHeader;
    this.AuthSignInSubText ||= defaults.identifiedSignInText;
    this.AuthSignUpCheckbox ||= defaults.signUpCheckboxText;
    this.AuthRecoveryHeader ||= defaults.forgotPasswordHeader;
    this.AuthRecoveryFormText ||= defaults.forgotPasswordText;
    this.AuthRecoveryConformationSubText ||=
      defaults.forgotPasswordConfirmationText;
    this.AuthSignInFormHeader ||= defaults.signInHeader;
    this.AuthSignInFormSubText ||= defaults.signInText;
    this.AuthSignInConfirmation ||= defaults.signInSuccessMessage;
    this.AuthVerificationHeader ||= defaults.emailVerifyHeader;
    this.AuthVerificationSubText ||= defaults.emailVerifyText;
    this.AuthSignInVerificationHeader ||= defaults.emailVerifyHeader;
    this.AuthSignInVerificationSubText ||= defaults.emailVerifyText;
    this.AuthSsoOAuthEnabled = asBoolean(props.AuthSsoOAuthEnabled);

    //Entry Conditions Defaults
    this.EntryConditionsTextColor ||= defaults.entryConditionsColor;
    this.EntryConditionsBackgroundColor ||= defaults.entryConditionsBgColor;
    this.EntryConditionsFilter = colorRgbaOrUndefined(
      props.EntryConditionsFilter
    );
    this.EntryConditionsType ||= defaults.entryConditionsType;
    this.EntryConditionsButtonText ||= defaults.entryConditionsSubmitText;
    this.EntryConditionsMinimumAge = asNumber(
      props.EntryConditionsMinimumAge,
      defaults.entryConditionsMinAge
    );

    //Cookie Consent Defaults
    this.CookieConsentTitle ||= defaults.cookieConsentTitle;
    this.CookieConsentContent ||= defaults.cookieConsentText;
    this.CookieConsentTextColor ||= defaults.cookieConsentColor;
    this.CookieConsentBackgroundColor ||= defaults.cookieConsentBgColor;
    this.CookieConsentButtonTextColor ||= defaults.cookieConsentButtonColor;
    this.CookieConsentButtonBackgroundColor ||=
      defaults.cookieConsentButtonBgColor;

    this.ContactVerifyShowSignInButton = asBoolean(
      props.ContactVerifyShowSignInButton
    );
    this.ContactVerifyOnFirstAppearance = asBoolean(
      props.ContactVerifyOnFirstAppearance
    );

    this.ContactVerifyAlwaysShowAllFields = asBoolean(
      props.ContactVerifyAlwaysShowAllFields
    );
  }
  getColorPalettes() {
    const palettes: ColorPalette[] = [];

    const brand = this.SimpleColors;
    if (brand.hasItems()) {
      brand.label = 'Brand Colors';
      palettes.push(brand);
    }

    // const valuePalette = new ColorPalette({ label: 'Values' });

    // valuePalette.addOption({
    //   label: 'Background',
    //   value: new ColorRGBA(this.BackgroundColor).hex
    // });
    // if (this.BackgroundFilter) {
    //   valuePalette.addOption({
    //     label: 'Header background',
    //     value: new ColorRGBA(this.HeaderBackgroundColor).hex
    //   });
    // }
    // valuePalette.addOption({
    //   label: 'Header background',
    //   value: new ColorRGBA(this.HeaderBackgroundColor).hex
    // });
    // valuePalette.addOption({
    //   label: 'Header text',
    //   value: new ColorRGBA(this.HeaderTextColor).hex
    // });
    // valuePalette.addOption({
    //   label: 'Footer background',
    //   value: new ColorRGBA(this.FooterBackgroundColor).hex
    // });
    // valuePalette.addOption({
    //   label: 'Footer text',
    //   value: new ColorRGBA(this.FooterTextColor).hex
    // });
    // valuePalette.addOption({
    //   label: 'Button background',
    //   value: new ColorRGBA(this.AccentBackgroundColor).hex
    // });
    // valuePalette.addOption({
    //   label: 'Button text',
    //   value: new ColorRGBA(this.AccentTextColor).hex
    // });

    // palettes.push(valuePalette);
    return palettes;
  }
}

export class ProfileDrawerModel {
  readonly _DefaultDescription: string = 'Complete actions to collect badges.';
  readonly _DefaultAlbumTabName: string = 'Badge album';
  description?: string;
  tabAccentColor?: string;
  albumTabName?: string;
  enableEdit?: boolean;
  textColor?: string = black;
  drawerColor?: string = white;
  constructor(props?: Partial<ProfileDrawerModel>) {
    props = props || {};
    Object.assign(this, props);
    this.enableEdit = asBoolean(props.enableEdit);
  }

  public static fromJsonOrNew(value: string) {
    return classFromJsonOrFallback(
      ProfileDrawerModel,
      value,
      new ProfileDrawerModel()
    );
  }
}

export class ContactVerifyFormModel {
  [immerable] = true;

  resendCodeButton: ButtonDataModel;
  cancelButton: ButtonDataModel;

  static defaults = {
    resendCodeButton: defaults.primaryButton,
    cancelButton: defaults.secondaryButton
  };

  constructor(props?: Partial<ContactVerifyFormModel>) {
    props = props || {};
    Object.assign(this, props);

    const defaults = ContactVerifyFormModel.defaults;
    this.resendCodeButton = new ButtonDataModel({
      backgroundColor: defaults.resendCodeButton.bgColor,
      color: defaults.resendCodeButton.color,
      borderColor: defaults.resendCodeButton.borderColor,
      ...this.resendCodeButton
    });
    this.cancelButton = new ButtonDataModel({
      backgroundColor: defaults.cancelButton.bgColor,
      color: defaults.cancelButton.color,
      borderColor: defaults.cancelButton.borderColor,
      ...this.cancelButton
    });
  }

  clone() {
    return new ContactVerifyFormModel(this);
  }

  public static fromJsonOrNew(
    value: string,
    fallback?: Partial<ContactVerifyFormModel>
  ) {
    return classFromJsonOrFallback(
      ContactVerifyFormModel,
      value,
      new ContactVerifyFormModel(fallback)
    );
  }
}

export class ContactVerifySignInModel {
  [immerable] = true;

  signInButton: ButtonDataModel;
  cancelButton: ButtonDataModel;

  header?: string;
  subHeader?: string;

  static defaults = {
    signInButton: defaults.primaryButton,
    cancelButton: defaults.secondaryButton
  };

  constructor(props?: Partial<ContactVerifySignInModel>) {
    props = props || {};
    Object.assign(this, props);

    const defaults = ContactVerifySignInModel.defaults;
    this.signInButton = new ButtonDataModel({
      backgroundColor: defaults.signInButton.bgColor,
      color: defaults.signInButton.color,
      borderColor: defaults.signInButton.borderColor,
      ...this.signInButton
    });
    this.cancelButton = new ButtonDataModel({
      backgroundColor: defaults.cancelButton.bgColor,
      color: defaults.cancelButton.color,
      borderColor: defaults.cancelButton.borderColor,
      ...this.cancelButton
    });
  }

  clone() {
    return new ContactVerifySignInModel(this);
  }

  public static fromJsonOrNew(
    value: string,
    fallback?: Partial<ContactVerifySignInModel>
  ) {
    return classFromJsonOrFallback(
      ContactVerifySignInModel,
      value,
      new ContactVerifySignInModel(fallback)
    );
  }
}
